/*eslint-disable */
// import grey from '../assets_gide/img/banners/e5e5e5_grey.png'
import { Careers } from '../../../FackApi/api/career.js'
import { CareerPbas } from '../../../FackApi/api/careerPba.js'

let CareerUtility = {
  showLoader: false,
  loadingMsg: "",
  /**
   * careerFieldList
   */
   async careerFieldList (self) {
     try {
        let careerFieldListResp = await Careers.careerFieldList(this)
        if (careerFieldListResp && !careerFieldListResp.resp_status) {
          return false
        }
        self.cvCareerFieldOptions = Object.values(careerFieldListResp.resp_data.data) 
        return self.cvCareerFieldOptions
      }
      catch (err) {
        console.error('Exception occurred at careerFieldList() and Exception:', err.message)
      }
  },
  /**
   * careerPbaTypeOptionsList
   */
   async careerPbaTypeOptionsList (self) {
    try {
      let careerTypeOptionsListResp = await CareerPbas.careerPbaTypeOptionsList(this)
      if (careerTypeOptionsListResp && !careerTypeOptionsListResp.resp_status) {
        return false
      }

      self.cvCareerTypeOptions = Object.values(careerTypeOptionsListResp.resp_data.data) 
      return self.cvCareerTypeOptions
    }
    catch (err) {
       console.error('Exception occurred at careerPbaTypeOptionsList() and Exception:', err.message)
    }
  },
}

export default CareerUtility
