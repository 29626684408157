/*eslint-disable */
// import CareerPbaModel from '../../Model/CareerPba'
import request from '../../Utils/curl'
import Utility from "../../Utils/utility"

let CareerPbas = {
  /**
   * careerPbaList
   */
  async careerPbaList (context) {
    Utility.showLoader = true;
    Utility.loadingMsg = "Please wait....";   
    try{
      let post_data = new FormData();
  
      return await request.curl(context, "careerPba_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at careerPbaList() and Exception:',err.message)
    }
    finally{
      Utility.showLoader = false;
    }
  },

  /**
   * careerPbaView
   */
  async careerPbaView (context, careerPbaId) {
    try {
      
      let post_data = new FormData();
      post_data.append('carr_pba_id', careerPbaId);
      return await request.curl(context, "careerPba_view", post_data)
      .then(async response => {
        return response;
      });
    }
    catch (err) {
      console.error("Exception occurred at careerPbaView() and Exception:",err.message)
    }
    
  },

  /**
   * careerPbaAdd
   */
  async careerPbaAdd (context, careerPbaObj) {
    try{
    let post_data = new FormData();
    
    for (let key in careerPbaObj) {
      post_data.append(key, careerPbaObj[key]);
    }

    return await request.curl(context, "careerPba_add", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at careerPbaAdd() and Exception:',err.message)
    }
  },

  /**
   * careerPbaEdit
   */
  async careerPbaEdit (context, careerPbaObj) {
    try{
    let post_data = new FormData();
    
    for (let key in careerPbaObj) {
      if (careerPbaObj[key] || typeof careerPbaObj[key] == 'number' ) {
        post_data.append(key, careerPbaObj[key]);
      }
    }

  return await request.curl(context, "careerPba_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at careerPbaEdit() and Exception:',err.message)
    }
  },

  /**
   * careerPbaDelete
   */
  async careerPbaDelete (context, careerPbaId) {
    try{
    let post_data = new FormData();
    
    post_data.append('carr_pba_id', careerPbaId);

    return await request.curl(context, "careerPba_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at careerPbaDelete() and Exception:',err.message)
    }
  },
  /**
   * careerPbaTypeOptionsList
   */
   async careerPbaTypeOptionsList (context) {
    try{
      let post_data = new FormData();
      return await request.curl(context, "careerPba_typeoptions_list", post_data)
      .then(async response => {
        console.log(response)
        return response
      });
    }
    catch(err){
      console.error('Exception occurred at careerPbaTypeOptionsList() and Exception:',err.message)
    }
  },  
}

export {
  CareerPbas
}
