/*eslint-disable */
<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">
              {{ cvCardTitle }}
            </h4>
          </template>
          <template v-slot:body>
            <p v-if="!propOpenedInModal">
              {{ cvCardSubHeader }}
            </p>
            <ValidationObserver ref="carrOppForm">
            <form action="#">
              <div class="form-row">
                <div class="col-md-12 mb-3">
                  <label
                    for="validationcarr_field">
                    {{cvCarrFieldLabel}}
                  </label>
                  <ValidationProvider
                      name="Career Field"
                      rules="required"
                      v-slot="{ errors }">
                    <multiselect
                      v-model="vmCareerPbaFormData.carr_field"
                      :options="cvCareerFieldOptions"
                      :placeholder="cvSelectBoxText + cvCarrFieldLabel"
                      label="carr_field"
                      track-by="carr_id">
                    </multiselect>
                    <span class="text-danger"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
                <div class="col-md-12 mb-3">
                    <label
                    for="validationcarr_field" class="w-100">
                    {{cvCarrImageLabel}}</label>
                    <div class="profile-img-edit border" style="height: 150px; width: 150px; border-radius: 50%; ">
                      <img v-if="vmProfilePic" :src="vmProfilePic" alt="profile-pic " style="height: 100%; width: 100%; object-fit: cover; overflow: hidden; border-radius: 50%; " />
                      <i v-else class="fas fa-university d-flex justify-content-center align-items-center rounded-circle " style="font-size: 5rem; height:100%; color: #c1c1c1;"></i>
                      <label class="p-image">
                        <i class="ri-pencil-line upload-button" style="cursor: pointer;"></i>
                        <input class="file-upload" type="file" accept="image/png image/jpeg" id="fileInput1" style="display: block; position: absolute; opacity: 0; left: 0; top: 0; pointer-events: none;" @change="openImageDialog($event, 'Profile Pic')" />
                      </label>
                    </div><br>
                  </div>
                <div class="col-md-12 mb-3">
                  <label
                    for="validationcarr_pba_type">
                    {{cvCarrPbaTypeLabel}}</label>
                    <ValidationProvider
                      name="Career Activity Tab Name"
                      rules="required"
                      v-slot="{ errors }">
                      <multiselect
                        v-model="vmCareerPbaFormData.carr_pba_type"
                        :options="cvCareerTypeOptions"
                        :placeholder="cvSelectBoxText + cvCarrPbaTypeLabel"
                        label="carr_pba_type"
                        track-by="carr_id">
                      </multiselect>
                      <span class="text-danger"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>

                <div class="col-md-12 mb-3">
                  <label
                    for="validationcarr_pba_meta">
                    Career Meta Title</label>
                    <ValidationProvider
                      name="Career Meta Titl"
                      rules="required"
                      v-slot="{ errors }">
                      <input
                        v-model="vmCareerPbaFormData.carr_pba_meta_title"
                        type="text"
                        class="form-control"
                        required/>
                    <span class="text-danger"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
                <div class="col-md-12 mb-3">
                  <label
                    for="validationcarr_pba_meta">
                    Career Meta Description</label>
                    <ValidationProvider
                      name="Career Meta description"
                      rules="required"
                      v-slot="{ errors }">
                    <textarea class="form-control" v-model="vmCareerPbaFormData.carr_pba_meta_description" rows="5" style="height:100px !important"></textarea>
                    <span class="text-danger"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
                <div class="col-md-12 mb-3">
                  <label
                    for="validationcarr_pba_meta">
                    Career Meta Image Link</label>
                    <input
                    v-model="vmCareerPbaFormData.carr_pba_meta_image_url"
                    type="text"
                    class="form-control"
                    placeholder="If you uploaded image then this is optional"
                    required/>
                </div>
                <div class="col-md-12 mb-3">
                  <label
                    for="validationcarr_pba_meta">
                    Career Click Link</label>
                    <input
                    v-model="vmCareerPbaFormData.carr_pba_meta_url"
                    type="text"
                    class="form-control"
                    required/>
                </div>
                <div class="col-md-12 mb-3">
                  <label
                    for="validationcarr_pba_meta">
                    Career Meta Author</label>
                    <ValidationProvider
                    name="Career Meta Author"
                    rules="required"
                    v-slot="{ errors }">
                      <input
                        v-model="vmCareerPbaFormData.carr_pba_meta_author"
                        type="text"
                        class="form-control"
                        required/>
                    <span class="text-danger"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
              </div>
              <div class="form-group row spinner_add">
                <button v-if="cvLoadingStatus" type="button" class="btn btn-primary float-right" :disabled="cvLoadingStatus">
                  <b-spinner label="Spinning">
                  </b-spinner>
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="careerPbaAdd()">
                  {{ cvSubmitBtn }}
                </button>
              </div>
            </form>
          </ValidationObserver>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <CropImage :prop-crop-image-dialog-open="propCropImageDialogOpen" :propCropModalSize="propCropDialogSize" :prop-stencil-size="propStencilSize" :propSrc="fileSrc" @emitCroppedImg="getCroppedImg" @emitCroppedImgFile="emitCroppedImgFile" />
    <b-toast
      v-model="showToast"
      name="Toast"
      :auto-hide-delay="toastVariant === 'danger' ? 10000 : 1000"
      :variant="toastVariant"
      :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </b-container>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import Multiselect from "vue-multiselect"
import { CareerPbas } from "../../../FackApi/api/careerPba"
import CareerUtility from "./CareerUtility"
import ApiResponse from "../../../Utils/apiResponse.js"
import CropImage from "../../../components/cropImage.vue"
import { Images } from "../../../FackApi/api/image.js"
import { ValidationProvider } from "vee-validate"

export default {
  name: "CareerPbaAdd",
  components: {
    Multiselect,
    CropImage,
    ValidationProvider
  },
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      cvSelectBoxText: "Select ",
      cvCarrImageLabel: "career Image",
      cvCardTitle: "Add Career Profile Building Activity",
      cvCardSubHeader: "Add Career Profile Building Activities ",
      cvSubmitBtn: "Add",
      cvCarrFieldLabel: "Career Name",
      cvCarrPbaTypeLabel: "Career Activity Tab Name",
      cvCarrPbaMetaLabel: "Career Meta Data",
      cvCareerFieldOptions: [],
      cvCareerTypeOptions: [],
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      cvLoadingStatus: false,
      toastTitle: "Career Profile Building Activity",
      vmCareerPbaFormData: Object.assign({}, this.initFormData()),
      propCropImageDialogOpen: false,
      propCropDialogSize: "md",
      propStencilSize: null,
      fileSrc: "",
      vmProfilePic: "",
      vmCarProPicImage: Object.assign({}, this.initImageData()),
      vmCareerPbaDesc: null,
      vmCareerPbaType: null
    }
  },
  async mounted () {
    await this.loadRequiredData()
  },
  methods: {
    /**
     * initFormData
     */
    initFormData () {
      return {
        "carr_field": "",
        "carr_pba_type": "",
        "carr_pba_meta": "",
        "carr_pba_meta_title": "",
        "carr_pba_meta_image_url": "",
        "carr_pba_meta_url": "",
        "carr_pba_meta_description": "",
        "carr_pba_meta_author": ""
      }
    },
    /**
     * loadRequiredData
     */
    async loadRequiredData () {
      this.cvCareerFieldOptions = await CareerUtility.careerFieldList(this)
      this.cvCareerTypeOptions = await CareerUtility.careerPbaTypeOptionsList(this)
      return CareerUtility.careerFieldList(this)
    },
    /**
     * initImageData
     */
    initImageData () {
      return {
        image: "",
        image_type: ""
      }
    },
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmCareerPbaFormData) {
          if (!this.vmCareerPbaFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
      }
    },
    /**
     * openImageDialog
     *
     */
    openImageDialog (e, imageType) {
      this.updateImageType = imageType
      if (e.target.files.length > 0) {
        this.fileSrc = e.target.files[0]
      }
      let input = e.target
      if (input.files.length > 0 && input.files[0]) {
        let image = input.files[0]
        let allowedExtension = [ "image/png", "image/jpg", "image/jpeg", "image/webp" ]
        let maxSize = 4 * 1024 * 1024
        if (!allowedExtension.includes(image.type)) {
          if (imageType === "Profile Pic") {
            this.toastMsg = this.cvImageExtensionErrorMsg
            this.showToast = true
          }
          else {
            this.toastMsg = this.cvImageExtensionErrorMsg
            this.showToast = true
          }
          return false
        }
        else if (image.size > maxSize) {
          // validate image size
          if (imageType === "Profile Pic") {
            this.toastMsg = this.cvImageSizeErrorMsg
            this.showToast = true
          }
          else {
            this.toastMsg = this.cvImageSizeErrorMsg
            this.showToast = true
          }
          return false
        }
        if (imageType === "Profile Pic") {
          this.propStencilSize = {
            "width": 150,
            "height": 150
          }
          this.propCropDialogSize = "md"
        }
        else {
          this.propStencilSize = {
            "width": 970,
            "height": 240
          }
          this.propCropDialogSize = "xl"
        }
        this.propCropImageDialogOpen = Math.random()
      }
    },
    /**
     * imageAdd
     */
    async imageAdd (carId) {
      if (this.cvImageError) {
        return
      }
      try {
        this.cvImageLoadingStatus = true
        let imageObj = {
          "module_name": "CARRPBAADD",
          "module_id": carId,
          "image": []
        }
        if (this.vmCarProPicImage.image) {
          imageObj.image.push(this.vmCarProPicImage)
        }
        if (imageObj.image.length < 1) {
          return false
        }

        const imageUpdateResp = await Images.imageProfileAdd(this, imageObj)
        await ApiResponse.responseMessageDisplay(this, imageUpdateResp)
        if (imageUpdateResp && !imageUpdateResp.resp_status) {
          return false
        }
        this.vmCarProPicImage = Object.assign({}, this.initImageData())
      }
      catch (err) {
        console.error("Exception occurred at imageAdd() and Exception:", err.message)
      }
      finally {
        this.cvImageLoadingStatus = false
      }
    },
    /**
     * onChangeProfileImage
     */
    onChangeProfileImage (e, imageType) {
      try {
        const reader = new FileReader()
        reader.onload = async (e) => {
          if (imageType === "Profile Pic") {
            this.vmProfilePic = await reader.result
          }
          else {
            this.vmCoverPic = await reader.result
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at onChangeProfilePic() and Exception:", err.message)
      }
    },
    /**
     * getCroppedImg
     */
    getCroppedImg (img) {
      if (this.updateImageType === "Profile Pic") {
        this.vmProfilePic = img
      }
      else {
        this.vmCoverPic = img
      }
    },
    /**
     * careerPbaAdd
     */
    async careerPbaAdd () {
      let valid = await this.$refs.carrOppForm.validate()
      if (!valid) {
        return
      }
      try {
        this.cvLoadingStatus = true
        this.vmCareerPbaFormData.carr_field = this.vmCareerPbaFormData.carr_field.carr_field
        this.vmCareerPbaFormData.carr_pba_type = this.vmCareerPbaFormData.carr_pba_type.carr_pba_type

        let careerPbaAddResp = await CareerPbas.careerPbaAdd(this, this.vmCareerPbaFormData)
        if (careerPbaAddResp.resp_data && careerPbaAddResp.resp_data.carr_pba_id) {
          this.cvLoadingStatus = false
          await this.imageAdd(careerPbaAddResp.resp_data.carr_pba_id)
        }
        await ApiResponse.responseMessageDisplay(this, careerPbaAddResp)
        if (careerPbaAddResp && !careerPbaAddResp.resp_status) {
          return false
        }
        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseCareerPbaAddModal", careerPbaAddResp.resp_data)
        }
        this.vmCareerPbaFormData = Object.assign({}, this.initFormData())
      }
      catch (err) {
        console.error("Exception occurred at careerPbaAdd() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    },
    /**
     * emitCroppedImgFile
     */
    emitCroppedImgFile (imgObj) {
      let img = imgObj.imgFile
      let orgImg = imgObj.orgImage
      let imageType = this.updateImageType
      this.onChangeProfileImage(img, imageType)
      if (imageType === "Profile Pic") {
        this.vmCarProPicImage = Object.assign({}, this.initImageData())
        this.vmCarProPicImage.image = {
          "img": img,
          "name": orgImg.name
        }
        this.vmCarProPicImage.image_type = "profile_pic"
      }
      else {
        this.vmCarCoverPicImage = Object.assign({}, this.initImageData())
        this.vmCarCoverPicImage.image = {
          "img": img,
          "name": orgImg.name
        }
        this.vmCarCoverPicImage.image_type = "cover_pic"
      }
    }
  }
}
</script>
